import { z } from 'zod';

import { Products } from '../events';

export enum ResourceTypes {
  Template = 'template',
  List = 'list',
}

export enum ResourceActions {
  Created = 'created',
  Updated = 'updated',
  Removed = 'removed',
}

export enum LimitTypes {
  FieldsPerTemplate = 'FieldsPerTemplate',
  PropertiesPerList = 'PropertiesPerList',
  ApprovalSignatureConditions = 'ApprovalSignatureConditions',
  UsesApprovalSignatureLogic = 'UsesApprovalSignatureLogic',
  TemplatePDFFilenameCustomFields = 'TemplatePDFFilenameCustomFields',
}

export const PlanLimitValueDetailSchema = z.object({
  resourceId: z.string(),
  resourceType: z.nativeEnum(ResourceTypes),
  workspaceId: z.string(),
  value: z.union([z.number(), z.boolean()]),
});
export interface IPlanLimitValueDetail extends z.infer<typeof PlanLimitValueDetailSchema> {}

export const PlanLimitValueSchema = z.object({
  limitType: z.nativeEnum(LimitTypes),
  accountId: z.string(),
  product: z.nativeEnum(Products),
  value: z.union([z.number(), z.boolean()]),
  valueDetails: z.array(z.lazy(() => PlanLimitValueDetailSchema)),
});
export interface IPlanLimitValue extends z.infer<typeof PlanLimitValueSchema> {}
